import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { HiHome } from 'react-icons/hi';
import { FaRegStar } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import { RiFolderVideoLine } from "react-icons/ri";

import { RiSmartphoneFill, RiHeartLine, RiHeartFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiTwotoneDislike, AiTwotoneLike } from 'react-icons/ai';
import { MdEmail, MdPhotoLibrary, MdNotificationsActive } from 'react-icons/md';
import { FaRandom, FaAddressCard } from 'react-icons/fa';
import { TbMessageCircle2 } from 'react-icons/tb';
import { IoMenu } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { HiOutlineWallet } from "react-icons/hi2";

import ReactPullToRefresh from 'react-pull-to-refresh';
import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import heart from 'react-useanimations/lib/heart';

import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

var logo = require('../assets/8fRM28h.png');
var reels = require('../assets/working-on.svg');

const notCompleted = () => toast('Saldo insuficiente para saque, complete a meta de R$ 2.000,00!');
const insufficient = () => toast('Você não pode solicitar saque de um valor maior do que possui na carteira!');
const missingData = () => toast('Dados insuficientes, insira sua chave pix para continuar!');
const dailyLimit = () => toast('Você alcançou o limite diário de R$30');
const termsAccepted = () => toast('Você aceitou os termos ✅!');

function Reels() {
    const navigate = useNavigate();
    const [money, setMoney] = useState(0);
    const [likeCount, setLikeCount] = useState(0);
    const [moneyToday, setMoneyToday] = useState(0);
    const [moneyFormated, setMoneyFormated] = useState('0');
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({});
    const [post, setPosts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [pixField, setPixField] = useState('');
    const [retrieve, setRetrieve] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [modalLimit, setModalLimit] = useState(false);
    const [modalPlan, setModalPlan] = useState(false);
    const [limit, setLimit] = useState(10);
    const [selectedStarsMap, setSelectedStarsMap] = useState({});
    const [limited, setLimited] = useState(false);

    const postsData = data;
    let date = '';

    const customStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
        },
    };  

    useEffect(() => {
        document.title = 'InstaPremium - Reels';

        auth.onAuthStateChanged(function(authUser) {
            if (authUser) {
            setUser({user});
            setRefreshing(true)
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];            
            } else {
            navigate('/login')
            }
        });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          const now = new Date();
          const target = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0); // Next day at midnight
          const diff = target - now;
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          setTimeLeft(formattedTime);
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);

      useEffect(() => {
        const getMoney = async () => {
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
            
            setLikeCount(likesCount);
    
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            const moneyValue = docSnap.data().money;
            setMoney(moneyValue);
            setUserEmail(docSnap.data().email);
       
            if (likeCount > limit) {
                setModalLimit(true)
            }
    
            let formattedMoney;
            if (!Number.isInteger(moneyValue)) {
                formattedMoney = moneyValue.toString().replace('.', ',');
            } else {
                formattedMoney = moneyValue;
            }
            setMoneyFormated(formattedMoney);
        }
        getMoney();
    }, [moneyFormated, user]);
    


    const logout = async () => {
        await signOut(auth);
    };



    return (
        <>
            <div className="App">
                <div class="wrapper">      
                <div className="header">
                    <img src={logo} width={100} />    
                    <div className="saldo">
                        <div style={{fontWeight: 'bold'}}><span>R$</span> {moneyFormated}</div>
                    </div>
                </div> 


                <div className="container" style={{marginTop: '25vh', padding: '0px 5%'}}>
                    <img src={reels.default} style={{filter: 'invert(1)', width: '40%'}}/>
                    <h2 style={{color: 'white', fontSize: 17}}>Ops! Os reels estão em manutenção.</h2>
                    <p style={{color: 'grey', fontSize: 14}}> Estamos realizando melhorias para aprimorar a sua experiência no app.</p>
                </div>

                <div className="bottomMenu">
                    <AiFillHome size={25} onClick={() => navigate('/')} color="#FFF"  />
                    <RiFolderVideoLine  size={25} onClick={() => navigate('/reels')} />
                    <FaRegStar   size={25} onClick={() => navigate('/promocao')}  />
                    <span className="novo">NOVO</span>

                    <HiOutlineWallet  size={25} onClick={() => navigate('/wallet')}  />
                </div>
                <Toaster />
                </div>
            </div>
        </>
    );
}

export default Reels;