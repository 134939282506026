import { useEffect, useState } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";
import { auth } from "./firebase-config";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Reels from "./pages/Reels";
import Wallet from "./pages/Wallet";
import Promocao from "./pages/Promocao";
// import Form from "./pages/Form";
// import Success from "./pages/Success";
// import Terms from "./pages/Terms";
// import Policy from "./pages/Policy"

function App() {
  const [showMobile, setShowMobile] = useState(true);
  const [money, setMoney] = useState(true)

  useEffect(() => {
    if(window.innerWidth > 765) {
      setShowMobile(false)
    }
  }, []);


  return (
    <Router>
      { showMobile &&
      <div className="wrapper">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/promocao' element={<Promocao />} />
          <Route path='/reels' element={<Reels />} />
          <Route path='/wallet' element={<Wallet />} />
        </Routes>
      </div>
      }
  </Router>
);
}

export default App;