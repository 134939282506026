import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from '@firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD70C3eKBjFGZjW6Ht53F1_7InIcZnqFO4",
  authDomain: "insta-premium-255c1.firebaseapp.com",
  projectId: "insta-premium-255c1",
  storageBucket: "insta-premium-255c1.appspot.com",
  messagingSenderId: "236986189802",
  appId: "1:236986189802:web:9281a7a85e2e824e8a9493",
  measurementId: "G-W8N18S59G8"
};

const app = initializeApp(firebaseConfig);


const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const provider = new GoogleAuthProvider();
