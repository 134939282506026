import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { HiHome } from 'react-icons/hi';
import { FaRegStar } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { RiFolderVideoLine } from "react-icons/ri";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { LiaIdCard } from "react-icons/lia";
import { MdMailOutline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import { HiOutlineWallet } from "react-icons/hi2";

import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

var logo = require('../assets/8fRM28h.png');
var reels = require('../assets/working-on.svg');

const notCompleted = () => toast('Você precisa bater a meta de avaliações para realiar seu saque!');

function Wallet() {
    const navigate = useNavigate();
    const [money, setMoney] = useState(0);
    const [likeCount, setLikeCount] = useState(0);
    const [moneyToday, setMoneyToday] = useState(0);
    const [moneyFormated, setMoneyFormated] = useState('0');
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({});
    const [post, setPosts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [selectedOption, setSelectedOption] = useState('phone');
    const [timeLeft, setTimeLeft] = useState(0);
    const [modalLimit, setModalLimit] = useState(false);
    const [limit, setLimit] = useState(10);
    const [isCellActive, setCellActive] = useState(true);
    const [isCpfActive, setCpfActive] = useState(false);
    const [isEmailActive, setEmailActive] = useState(false);


    const postsData = data;
    let date = '';

    const customStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
        },
    };  

    useEffect(() => {
        document.title = 'InstaPremium - Saldo';
        auth.onAuthStateChanged(function(authUser) {
            if (authUser) {
            setUser({user});
            setRefreshing(true)
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];            
            } else {
            navigate('/login')
            }
        });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          const now = new Date();
          const target = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0); // Next day at midnight
          const diff = target - now;
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          setTimeLeft(formattedTime);
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);

      useEffect(() => {
        const getMoney = async () => {
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
            
            setLikeCount(likesCount);
    
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            const moneyValue = docSnap.data().money;
            setMoney(moneyValue);
            setUserEmail(docSnap.data().email);
       
            if (likeCount > limit) {
                setModalLimit(true)
            }
    
            let formattedMoney;
            if (!Number.isInteger(moneyValue)) {
                formattedMoney = moneyValue.toString().replace('.', ',');
            } else {
                formattedMoney = moneyValue;
            }
            setMoneyFormated(formattedMoney);
        }
        getMoney();
    }, []);
    


    const logout = async () => {
        await signOut(auth);
    };



    return (
        <>
            <div className="App">
                <div class="wrapper">      
                <div className="header">
                    <img src={logo} width={100} />    
                    <div className="saldo">
                        <div style={{fontWeight: 'bold'}}><span>R$</span> {moneyFormated}</div>
                    </div>
                </div> 


                <div className="container saqueWrapper" style={{marginTop: '5vh', padding: '0px 5%'}}>
                    <div className="cardGrey" style={{marginBottom: 10, padding: '10px 5%'}}>
                        <h4 className="align-items-center d-flex" style={{gap: 7, margin: '5px 0px'}}><FaCheckCircle />Meta de avaliações</h4>
                        <ProgressBar  
                            completed={0} 
                            maxCompleted={100} 
                            className="wrapperBar"
                            barContainerClassName="containerBar"
                            completedClassName="barCompletedBar"
                            labelClassName="labelBar" 
                            labelAlignment="left"
                            customLabel="0.00%" />
                    </div>
                    <div className="cardGrey">
                        <h3>SEU SALDO</h3>
                        <h2>R$ {moneyFormated}</h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{margin: 10}}>
                        <FaPix color="#4BB8A9" style={{marginRight: 5}} />
                        <p>Selecione sua chave PIX</p>
                    </div>
                    <div className="wrapperOptions">
                        <div onClick={() => {
                            setCellActive(true);
                            setCpfActive(false)
                            setEmailActive(false)
                        }} className={isCellActive ? 'active option' : 'option'} id="phone">
                            <HiOutlineDevicePhoneMobile size={25}  />
                            Celular
                        </div>
                        <div onClick={() => {
                            setCellActive(false);
                            setCpfActive(true)
                            setEmailActive(false)
                        }} className={isCpfActive ? 'active option' : 'option'}  id="phone">
                            <LiaIdCard size={25}  />
                            CPF
                        </div>
                        <div onClick={() => {
                            setCellActive(false);
                            setCpfActive(false)
                            setEmailActive(true)
                        }} className={isEmailActive ? 'active option' : 'option'}  id="phone">
                            <MdMailOutline size={25}  />
                            E-mail
                        </div>
                    </div>

                    <input className={isCellActive ? 'd-flex' : 'd-none'} placeholder="Digite seu número aqui..." />
                    <input className={isCpfActive ? 'd-flex' : 'd-none'} placeholder="Digite seu CPF aqui..." />
                    <input className={isEmailActive ? 'd-flex' : 'd-none'} placeholder="Digite seu e-mail aqui..." />

                    <button className="buttonBlue" onClick={() => {notCompleted()}}>REALIZAR SAQUE</button>
                </div>

                <div className="bottomMenu">
                    <AiFillHome size={25} onClick={() => navigate('/')} color="#FFF"  />
                    <RiFolderVideoLine  size={25} onClick={() => navigate('/reels')} />
                    <FaRegStar   size={25} onClick={() => navigate('/promocao')}  />
                    <span className="novo">NOVO</span>
                    <HiOutlineWallet  size={25} onClick={() => navigate('/wallet')}  />
                </div>
                <Toaster />
                </div>
            </div>
        </>
    );
}

export default Wallet;