export default [
    {
        'user': 'Anitta 🎤',
        'perfil': 'https://br.web.img2.acsta.net/pictures/20/11/19/16/21/4456129.jpg',
        'img': 'https://pbs.twimg.com/media/GGGEKJzXkAASTpR?format=jpg&name=4096x4096',
        'likes': '897'
    },
    {
        'user': 'Alok',
        'perfil': 'https://pbs.twimg.com/media/F9xDMdnXMAAZr7Z?format=jpg&name=large',
        'img': 'https://pbs.twimg.com/media/F7yGWuaXoAAWiJM?format=jpg&name=large',
        'likes': '159'
    },
    {
        'user': 'Neymar',
        'perfil': 'https://pbs.twimg.com/media/GGpOLuJXkAAw8X1?format=jpg&name=large',
        'img': 'https://pbs.twimg.com/media/GFgeGqXXwAAKu_i?format=jpg&name=large',
        'likes': '354'
    },
    {
        'user': 'Ronaldinho',
        'perfil': 'https://pbs.twimg.com/profile_images/1537593562619060225/AmmxIwWi_400x400.jpg',
        'img': 'https://pbs.twimg.com/media/GMfaEgqWcAA30LB?format=jpg&name=large',
        'likes': '567'
    },
    {
        'user': 'Ana Hickmann',
        'perfil': 'https://pbs.twimg.com/profile_images/1787882225960206336/AUdnhHmB_400x400.jpg',
        'img': 'https://pbs.twimg.com/media/GNLDInKXMAA_v8u?format=jpg&name=large',
        'likes': '120'
    },
    {
        'user': 'Sabrina Sato',
        'perfil': 'https://pbs.twimg.com/profile_images/1636371226288349184/dPtsMvEa_400x400.jpg',
        'img': 'https://pbs.twimg.com/media/GPQi0H7WUAArTKP?format=jpg&name=large',
        'likes': '484'
    },
]