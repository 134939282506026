import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { HiHome } from 'react-icons/hi';
import { FaRegStar } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import { RiFolderVideoLine } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";

import { RiSmartphoneFill, RiHeartLine, RiHeartFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiTwotoneDislike, AiTwotoneLike } from 'react-icons/ai';
import { MdEmail, MdPhotoLibrary, MdNotificationsActive } from 'react-icons/md';
import { FaRandom, FaAddressCard } from 'react-icons/fa';
import { TbMessageCircle2 } from 'react-icons/tb';
import { IoMenu } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { HiOutlineWallet } from "react-icons/hi2";

import ReactPullToRefresh from 'react-pull-to-refresh';
import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import heart from 'react-useanimations/lib/heart';

import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

var logo = require('../assets/8fRM28h.png');
var logo2 = require('../assets/LBRegsc.png');
var banner200 = require('../assets/200.jpeg');
var banner100 = require('../assets/100.jpeg');

const notCompleted = () => toast('Saldo insuficiente para saque, complete a meta de R$ 2.000,00!');
const insufficient = () => toast('Você não pode solicitar saque de um valor maior do que possui na carteira!');
const missingData = () => toast('Dados insuficientes, insira sua chave pix para continuar!');
const dailyLimit = () => toast('Erro ao responder pesquisa ❌');
const termsAccepted = () => toast('Você aceitou os termos ✅!');

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


function Home() {
    const navigate = useNavigate();
    const [money, setMoney] = useState(0);
    const [likeCount, setLikeCount] = useState(0);
    const [moneyToday, setMoneyToday] = useState(0);
    const [moneyFormated, setMoneyFormated] = useState('0');
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({});
    const [post, setPosts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [pixField, setPixField] = useState('');
    const [retrieve, setRetrieve] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [modalLimit, setModalLimit] = useState(false);
    const [modalPlan, setModalPlan] = useState(false);
    const [limit, setLimit] = useState(10);
    const [selectedStarsMap, setSelectedStarsMap] = useState({});
    const [limited, setLimited] = useState(false);
    const [modalSuperPost, setModalSuperPost] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal200, set200] = useState(false);
    const [modal100, set100] = useState(false);
    const [alreadyLiked, setAlreadyLiked] = useState(false);
    const [checked, setChecked] = useState(false);
    const [terms, setTerms] = useState(false);

    

    const postsData = data;
    let date = '';

    const customStyles = {
        content: {
        background: 'rgb(31, 31, 31)',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #494949',
        width: '89%',
        borderRadius: '13px'
        },
    };  

    const customStyles2 = {
        content: {
        background: 'transparent',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        border: 'none',
        borderRadius: '13px'
        },
    };  

    const customStyles3 = {
        content: {
        background: '#0a0a0a',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #494949',
        width: '89%',
        borderRadius: '13px'
        },
    };  


    useEffect(() => {
        document.title = 'InstaPremium - Home';

        auth.onAuthStateChanged(function(authUser) {
            if (authUser) {
            setUser({user});
            setRefreshing(true)
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];

            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;

            if (localStorage.getItem('open')) {
                setLoading(false);
                set200(true);
            } else {
                setLoading(true);
                console.log(localStorage.getItem('open'))
                setTimeout(() => {
                    setLoading(false);
                    setTerms(true);
                    localStorage.setItem('open', true);
                }, 2000);
            }

            setPosts(getMultipleRandom(1));

            } else {
            navigate('/login')
            }
        });
    }, []);



    useEffect(() => {
        const intervalId = setInterval(() => {
          const now = new Date();
          const target = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0); // Next day at midnight
          const diff = target - now;
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          setTimeLeft(formattedTime);
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);

      useEffect(() => {
        const getMoney = async () => {
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
            
            setLikeCount(likesCount);
    
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            const moneyValue = docSnap.data().money;
            setMoney(moneyValue);
            setUserEmail(docSnap.data().email);
            console.log('teste', docSnap.data().email, docSnap.data().money)

            if (likeCount > limit) {
                setModalLimit(true);
                setAlreadyLiked(true);
            }
    
            let formattedMoney;
            if (!Number.isInteger(moneyValue)) {
                formattedMoney = moneyValue.toString().replace('.', ',');
            } else {
                formattedMoney = moneyValue;
            }
            setMoneyFormated(formattedMoney);
        }
        getMoney();

    }, [moneyFormated, user]);
    

    const getMultipleRandom = (num) => {
        const shuffled = [...postsData].sort(() => 0.5 - Math.random());

        return shuffled.slice(0, num);
    }

    const updateMoney = async (val) => {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
        const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;

        if (likesCount < 1) {
            console.log('contagem', likesCount)
            setRefreshing(true)
            const newClicks = likesCount + 1;
            localStorage.setItem(currentDate, newClicks);
            
            setLikeCount(newClicks);
            localStorage.setItem(`${currentDate}-timestamp`, new Date().getTime());

            const userMoney = doc(db, "users", auth.currentUser.uid);
            const total = money + 1.5;
            const newMoney = { money: total};
            const isNumber = typeof total;
            if (isNumber == 'number') {
              await updateDoc(userMoney, newMoney);
              setMoney(total);
              setMoneyFormated(total.toString().replace('.', ','))
            }
            
            if (localStorage.getItem(date)) {
                var totalToday = parseFloat(localStorage.getItem(date)) + 1;
            } else {
                var totalToday = 1;
            }
    
            setMoneyToday(totalToday);
            localStorage.setItem(date, totalToday);
    
            if (!Number.isInteger(total)) {
              let formated = total.toString().replace('.', ',');
              setMoneyFormated(formated);
            } else {
              setMoneyFormated(total);
            }
            if (post.length == 1) {
                setLimited(true);

            }
        } else {
            dailyLimit();
            setAlreadyLiked(true);
        }

      }

    const logout = async () => {
        await signOut(auth);
    };

    function handlePostClick() {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
        const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;

        if (likesCount < 1) {
            setModalSuperPost(true);
        }

        if (alreadyLiked) {
            setModalSuperPost(true);
        } 
        updateMoney();
      }

    const sacar = () => {
        if (pixField == '') {
            missingData()
        } else if (retrieve > moneyFormated) {
            insufficient()
        } else if (money < 2000) {
            notCompleted();
        }
    }

    const handleStarClick = (postId, index) => {
        const updatedSelectedStarsMap = { ...selectedStarsMap };
        updatedSelectedStarsMap[postId] = Array.from({ length: 5 }, (_, i) => i <= index);
        setSelectedStarsMap(updatedSelectedStarsMap);

        setPosts(posts => posts.filter(post => post.user !== postId));
        updateMoney(0.5)
    };

    const listPosts = post.map((val, index) =>
    <>
        <div className="publiWrapper" key={uuid()}>
            <div className="headerPubli d-flex align-items-center justify-content-between">
                <div>
                    <img src={val.perfil} style={{width: 35}} />
                    <h2>{val.user}</h2>
                    <img src={require(`../assets/check.png`)} style={{width: 15}} />
                    <span className="superpost">SUPER POST</span>
                </div>
                <BsThreeDots  />
            </div>
            <img className="post" style={{width: '100%'}} src={val.img} />
            <div className="d-flex justify-content-between align-items-center">
                <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <FaHeart color="red" />
                    <p>{val.likes} mil curtidas</p>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark" viewBox="0 0 16 16">
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"></path>
                </svg>
            </div>
            <div className="interesse">
                <p>Você tem interesse nessa publicação?</p>
                <div className="btns-interesse">
                    <button className="nao" onClick={() => {handlePostClick()}}>X Não</button>
                    <button className="sim" onClick={() => {handlePostClick()}}>&#10003; Sim</button>
                </div>
            </div>
        </div>
    </>
    );

    function closeModal() {
        setModalPlan(false);
    }

    return (
        <>
            <div className="App">
            <title>My Blog</title>

                <div class="wrapper">
                <Modal
                    isOpen={loading}
                    style={customStyles2}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <img className="loading" style={{width: '70px'}} src={logo2} />
                </Modal>

                <Modal
                    isOpen={terms}
                    style={customStyles3}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <h2 style={{color: 'white'}}>TERMOS DE USO</h2>
                    <p style={{color: '#494949', margin: 0}} >Para utilizar o app, você precisa aceitar os termos de condições de uso.</p>
                    <div className="termosWrapper" style={{maxHeight: '300px', overflow: 'scroll'}}>
                        <img src={logo} />
                        <h2 style={{color: 'white'}}>Termos e condições de uso de aplicativo</h2>
                        <h3>ACEITAÇÃO</h3>
                        <p>Os presentes “Termos e Condições de Uso de Aplicativo” regem o uso do aplicativo “INSTAPREMIUM”, e são válidos para toda e qualquer opção de uso, seja para dispositivos móveis (Android, IOS, Windows Mobile), servidores, computadores pessoais (desktops) ou serviço web. Se você não concordar com estes termos não deve utilizar este aplicativo.
Leia atentamente pois o uso do aplicativo significa que você aceitou todos os termos e concorda em cumpri-los. Se você for menor de idade ou considerada incapaz pela legislação brasileira para manifestar sua aceitação, precisará da permissão de seus pais ou responsáveis, que também deverão concordar com estes mesmos termos e condições.</p>

                        <h3>LICENÇA LIMITADA</h3>
                        <p>Você recebeu uma licença limitada, não transferível, não exclusiva, livre de royalties e revogável, que inclui baixar, instalar, executar e utilizar este aplicativo em seu dispositivo. Você reconhece e concorda que detém uma licença exclusiva para uso do app e desta forma não lhe transfere os direitos sobre o produto.</p>


                        <h3>UTILIZAÇÃO DO APLICATIVO</h3>
                        <p>O aplicativo deverá ser utilizado por você. A venda, transferência, modificação, engenharia reversa ou distribuição bem como a cópia de textos, imagens ou quaisquer partes nele contido é expressamente proibida. O aplicativo permite a você acumular créditos em reais, gerados a partir de avaliações positivas ou negativas de fotos, realizadas por você.
As fotos deverão ser avaliadas por você e serão disponibilizadas no aplicativo de forma escalonada, reduzindo-se gradualmente o número de postagens disponibilizadas a cada nova semana de utilização</p>
                        <p>O prazo necessário para você avaliar todas as fotos que serão disponibilizadas e, em consequência dessas avaliações, permitir que você tenha acesso ao saldo acumulado (Caso atinja as metas e cumpra as regras para saque), será de 6 meses, mas você poderá cumprir a meta em até 12 meses, que é o prazo de duração da licença de uso do aplicativo, a partir do qual seu acesso estará expirado e novas avaliações dependerão de renovação da licença.</p>
                        <p>O saque do saldo acumulado será liberado em até 48 horas após a solicitação, mediante transferência bancária para a conta que você informará no momento que realizar o seu cadastro no aplicativo, conta que deverá ser de sua titularidade.
Atingido o prazo de expiração da licença de uso sem que você tenha solicitado o saque, o valor do saldo acumulado será creditado automaticamente na conta informada em até 30 (trinta) dias a partir da expiração da licença.</p>

                        <h3>CONDIÇÕES DE CONTRATAÇÃO</h3>
                        <p>Para utilizar o aplicativo, você se obriga a conferir o valor da meta a ser atingida, o valor do crédito que será gerado por cada avaliação realizada e o número de imagens a serem disponibilizadas em cada campanha.</p>
                        <p>O fornecimento de informações cadastrais inverídicas resultará, a qualquer tempo, na exclusão de sua conta, e você se responsabiliza por manter as informações de login e senha para seu exclusivo uso pessoal e intransferível.</p>

                        <h3>ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO
</h3>
                        <p>Os presentes termos poderão ser modificados, seja incluindo, removendo ou alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato. Após publicadas tais alterações, ao continuar com o uso do aplicativo, você terá aceitado e concordado em cumprir os termos modificados.</p>
                        <p>Você deve estar ciente de que, de tempos em tempos, se poderá modificar ou descontinuar (temporária ou permanentemente) a distribuição ou a atualização deste aplicativo.
Não existe obrigação de fornecer nenhum serviço de suporte para este aplicativo. O usuário não poderá responsabilizar o produtor do aplicativo nem seus diretores, executivos, funcionários, afiliados, agentes, contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidades do aplicativo.</p>
<p>Esta contratação poderá ser rescindida em razão do descumprimento dos presentes termos e condições de uso, por inatividade da usuária em período igual ou superior a 30 dias, assim como, em razão de caso fortuito ou força maior.</p>

<h3>CONSENTIMENTO PARA COLETA E USO DE DADOS E PUBLICIDADE
</h3>
<p>Ao fazer uso do aplicativo, você concorda que se poderá coletar e usar dados técnicos de seu dispositivo tais como especificações, configurações, versões de sistema operacional, tipo de conexão à internet e afins.</p>
<p>A produtora do aplicativo se obriga a manter seus dados pessoais em segurança, submetendo todo e qualquer tratamento de dados à disciplina da Lei nº 13.709/18 - Lei Geral de Proteção de Dados.
Ao utilizar o aplicativo, você concorda com a presença de anúncios e publicidades nele contidos, que deverão ser destacados como tais, cabendo exclusivamente a você a responsabilidade em caso de compras, visitas ou acessos aos links e endereços dos anunciantes.</p>

<h3>DA RESPONSABILIDADE
</h3>
<p>Este aplicativo estará em contínuo desenvolvimento e pode conter erros e, por isso, o uso é fornecido “no estado em que se encontra” e sob risco do usuário final.</p>
<p>A responsabilidade do produtor do app se restringe à oferta e atualização do aplicativo, caso haja necessidade, bem como a obrigação de transferência bancária nos exatos termos estabelecidos neste documento de acordo com os valores e metas informados em cada campanha.</p>
<p>É de sua inteira responsabilidade os danos e prejuízos que eventualmente vier a causar durante a utilização do aplicativo, não respondendo a produtora do app por nenhum ônus daí decorrente.</p>



<h3>DAS CONDIÇÕES DE SAQUE
</h3>
<p>O saque será liberado assim que a meta de avaliações for batida dentro do aplicativo.</p>
<p>Quando o saque for solicitado, as avaliações serão auditadas e serão descontadas as avaliações não válidas, dentro do seguinte critério: serão consideradas avaliações não válidas as avaliações não respondidas, ou respondidas com menos de uma justificativa embasando a respostas escolhida. Sendo que não são válidas justificativas como “porque sim”, “porque eu gosto”, “porque eu achei bonito”, “porque eu quero” e semelhantes.</p>
<p>Podem ocorrer janelas de antecipações, em que é liberada a opção de antecipar o saque antes das metas de avaliações dos usuários serem batidas, porém com o desconto de taxas de antecipação que serão calculadas conforme a quantidade de avaliações não válidas do usuário.</p>
<p>O cupom de “desbloqueio de saldo” permite que você desbloqueie o limite saldo máximo no aplicativo InstaPremium para um valor maior, permitindo que você receba valores maiores quando bater a meta de avaliações, desde que de acordo com as condições de pagamento anteriormente mencionadas nos termos de uso.</p>
<p>O cupom "mais 200 avaliações" aumentará o seu volume de avaliações disponíveis em até 4 avaliações extras por dia, até que se esgote as 200 avaliações. Mas não contabilizarão na meta.</p>
<p>Os cupons comprados a parte (tais como cupom “ + 100 de saldo”, cupom “duplique seu saldo” ou cupom de “desbloqueio de saldo” ) são adicionados no saque quando a meta é batida, e não se aplica a antecipações ou a metas batidas com menos de 85% das avaliações consideradas não validas.</p>
<p>Também não assumimos responsabilidade por sites piratas, copias ou semelhantes do InstaPremium, que não são o site em que está hospedado este termo de uso.</p>
<h3>DA GRATUIDADE</h3>
<p>Com exceção dos produtos e dos cupons vendidos dentro do aplicativo, a licença de acesso ao InstaPremium é GRATUITA sob qualquer circunstância.</p>
<p>É autorizada a venda de infoprodutos em geral vendendo a informação do modo de acesso, de criação de conta, licença de uso, ou de como melhor utilizar o InstaPremium como fonte de renda extra, assim como os códigos cupons, porém não assumimos responsabilidade pelos atendimento ou pelo reembolso de valores cobrados por esses produtos, uma vez em que se pode acessar o InstaPremium gratuitamente pelo site em que se hospeda este documento.</p>
<h3>DO FORO</h3>
<p>Eventual demanda ou controvérsia decorrente destes Termos e Condições de Uso deverão ser dirimidas no foro da Comarca de Maringá-PR, com renúncia de qualquer outro, por mais privilegiado que seja.</p>
                    </div>
                    <div className="d-flex align-items-center" style={{color: 'white'}}>
                        <input onChange={() => {
                            setChecked(!checked);
                        }} style={{width: 19, height: 17}} checked={checked} type="checkbox" />
                        <p style={{color: 'grey', fontSize: 12}}>                        Declaro que li e aceito os termos de uso do aplicativo
</p>
                    </div>
                    <button className={checked ? "buttonBlue" : "buttonBlue disabled"} onClick={() => {
                        setTerms(false);
                        set200(true);
                    }}>ACESSAR APP</button>

                </Modal>

                <Modal
                    isOpen={modal200}
                    style={customStyles}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >

                    <img style={{width: '100%', marginBottom: 10}} src={banner200} />

                    <button className="buttonBlue"  onClick={() => {window.open('https://checkout.perfectpay.com.br/pay/PPU38COKO5Q?src=app_200&hidecard=1')}}>EU QUERO!</button>
                    <button className="buttonBlack" onClick={() => {
                        set200(false);
                        setTimeout(() => {
                            set100(true);
                        }, 300);
                    }}>Não, obrigado</button>

                </Modal>

                <Modal
                    isOpen={modal100}
                    style={customStyles}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >

                    <img style={{width: '100%', marginBottom: 10}} src={banner100} />

                    <button className="buttonBlue"  onClick={() => {window.open('https://checkout.perfectpay.com.br/pay/PPU38COKO79?src=app_100&hidecard=1')}}>EU QUERO!</button>
                    <button className="buttonBlack" onClick={() => {
                        if (localStorage.getItem('open')) {
                            set100(false)
                            setModalSuperPost(true);
                        } else {
                            set100(false)
                        }
                    }}>Não, obrigado</button>

                </Modal>

                <Modal
                    isOpen={modalSuperPost}
                    style={customStyles}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <h1 style={{color: '#7C3AED', margin: 0}}>OPS!</h1>
                    <p>Você ainda não adquiriu os <span style={{color: '#E5C046'}}>SUPER POSTS!</span></p>
                    <p>Adquira os <span style={{color: '#E5C046'}}>SUPER POSTS</span> com um super desconto HOJE e garanta um post com valor <span style={{color: '#7C3AED'}}>BÔNUS todos os dias!</span></p>
                    
                    <div className="interesse" style={{textAlign: 'center', marginBottom: 15, justifyContent: 'center', display: 'flex'}}>
                        <p>Se você tivesse o pacote de SUPER POSTS, teria ganho <span style={{color: '#E5C046'}}>R$ 161,12</span> por avaliar esse post</p>
                    </div>

                    <button className="btnDourado" onClick={() => {window.open('https://checkout.perfectpay.com.br/pay/PPU38COKO9A?src=app_super-post&hidecard=1')}}>ADQUIRIR AGORA</button>
                    <button className="buttonBlack" onClick={() => {setModalSuperPost(false);             setPosts(getMultipleRandom(1));
}}>Não, obrigado</button>

                </Modal>
      
                <div className="header">
                    <img src={logo} width={100} />    
                    <div className="saldo">
                        <div style={{fontWeight: 'bold'}}><span>R$</span> {moneyFormated}</div>
                    </div>
                </div> 
                <div className="feedWrapper" style={{margin: '0px 2%'}}>
                    {
                        1+1 !== 2 ?
                        <h3 style={{color: 'grey'}}>Você já respondeu todas as pesquisar disponíveis, volte novamente amanhã!</h3>
                        :
                        <>
                             {listPosts}
                        </>
                    }
                </div>

                <div className="bottomMenu">
                    <AiFillHome size={25} color="#FFF"  />
                    <RiFolderVideoLine  size={25} onClick={() => navigate('/reels')} />
                    <FaRegStar   size={25} onClick={() => navigate('/promocao')}  />
                    <span className="novo">NOVO</span>
                    <HiOutlineWallet  size={25} onClick={() => navigate('/wallet')}  />
                </div>
                <Toaster />
                </div>
            </div>
        </>
    );
}

export default Home;